<template>
    <div class="demo-main-wrapper">
        <div v-if="issue != null" class="h1-font">
                <h1 style="font-size:6vw">ISSUE {{ issue.issueID }}</h1>
                <v-divider></v-divider>
        </div>
        <div>
            <v-card id="top" elevation="0" color="rgba(255,0,0,0.0)">
                <v-img v-if="issue != null" class="demo-front-cover" :src="issue.cover" contain eager>
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular :size="50" color="purple" indeterminate></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
                <div class="text-center">
                    <i v-if="issue != null" class="font-italic">Cover art by: {{issue.artist}}</i>
                </div>
            </v-card>
        </div>
        <div>
            <div class="issue-container">
                <v-card>
                    <div class="demo-display-content" v-if="issue != null" v-html="issue.safeHTML"></div>
                </v-card>
            </div>
        </div>

        <v-fab-transition>
            <v-btn v-show="true" fab dark fixed bottom right color="#EA80FC" @click="toTop()">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
import PostService from "../PostService.js"; 

export default {

    components: {

    },

    mounted() {
        document.title += " Issue " + this.$route.params.issueid;
    },

    async beforeCreate() {
        try {
            var issues = await PostService.getIssues();
            this.issue = issues.filter(e => e.issueID === parseFloat(this.$route.params.issueid))[0];
        } catch(err) {
            console.log(err.message);
        }
    },

    methods: {
        scrollToSeg(key) {
            key = key.replace(/\s+/g, '-').toLowerCase();
            let s = document.querySelector("#" + key); 
            s.scrollIntoView({behavior: "smooth" }); 
        }, 

        fixID(badID) {
            let fixedID = badID.replace(/\s+/g, '-').toLowerCase();
            return fixedID; 
        },
        
        toTop() {
            document.querySelector("#top").scrollIntoView({behavior: "smooth"});
        },

    },

    data() {
        return {
            // Tracks current async processes
            isActive: false, 
            issue: null,
            cover: null,
            err: "",

            gradients: [
                ['#00c6ff', '#F0F', '#FF0'],
            ]

        }
    }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@300;400;500;600;700&display=swap");
    .demo-main-wrapper {
        height: 100%;
        width: auto;
        margin: 0 auto;
    }

    .h1-font {
        font-family: "Bebas Neue", sans-serif;
        font-style: italic;
    }

    .demo-front-cover {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 1vw;
    }

    .issue-container {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .demo-display-content {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .img-fit {
        padding: 10px;
        margin: 0 auto;
        max-width: 600px;
        width: 80vw;
        object-fit: contain;
    }


    @media only screen and (min-width: 768px) {
        .demo-main-wrapper {
            margin-left: 20px; 
            margin-right: 20px; 
        }

        .demo-front-cover {
            margin-left: 10vw;
            margin-right: 10vw;
        }
    }

    @media only screen and (min-width: 1100px) {
        .demo-main-wrapper {
            margin-left: 15vw;
            margin-right: 15vw; 
        }
    }

</style>