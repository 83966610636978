<template>
    <div class="feeds-main-wrap">
        <div>
        <h1>Feeds:</h1>
        <ul>
            <li>
                <a href="/feeds/rss.xml">Issues</a>
            </li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .feeds-main-wrap {
        height: 100%;
        display: flex;
        justify-content: center;
    }
</style>