<template>
    <div class="staff-wrapper">
        <div class="staff-content">
            <div>
                <h1 style="font-size:6vw">OUR STAFF</h1>
                <v-divider></v-divider>
            </div>
            <div>
                <v-container fluid>
                    <v-row>
                        <v-col v-for="(card,idx) in staff_cards" :index="idx" :key="card" cols="12" sm="6">
                            <v-img :src="imgAtIndex(idx)" :aspect-ratio="aspectRatio" contain></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "staffcards",
    data() {
        return {
            staff_cards: [
                "avira-id.png",
                "levi-id.png",
                "lilli-id.png",
                "reed-id.png",
                "yesh-id.png",
                "shetani-id.png",
                "wolfy-id.png",
                "lucky-id.png",
                "danielle-id.png"
            ],
            aspectRatio: 16/9,
        }
    },
    methods: {
        imgAtIndex(idx) {
            return require("../assets/id-cards/" + this.staff_cards[idx]);
        }
    }
}
</script>

<style lang="scss" scoped>
.staff-content {
    width: 100%;
    height: auto;
}

</style>