<template>
    <div class="archives-wrapper">
        <div class="archives-content">
            <div v-if="checkAdmin()" class="archives-add">
                <v-btn fab dark color="primary" @click="enterEditor()"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
            <div v-if="issues != null" class="archives-grid">
                <v-container>
                    <v-row>
                        <v-col v-for="(issue, index) in issues" :key="index" cols="12" sm="4">
                            <router-link :to="{ name: 'ViewIssue', params: { issueid: issue.issueID } }" style="text-decoration: none; color: inherit;">
                                <IssueCard :cover=issue.cover :issueID=issue.issueID ></IssueCard>
                            </router-link>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div v-else>
                <v-skeleton-loader type="article"></v-skeleton-loader>
            </div>
        </div>
    </div>
</template>

<script>
import IssueCard from "../components/IssueCard.vue";
import PostService from "../PostService";
export default {
    name: "archives",
    components: { IssueCard },
    data() {
        return {
            issues: null
        }
    },

    async beforeCreate() {
        try {
            var res = await PostService.getIssues();
            this.issues = res.sort((a, b) => (a.issueID < b.issueID) ? 1 : -1)
        } catch(err) {
            console.log(err.message);
        }
    },

    methods: {
        checkAdmin() {
            return this.$store.getters.getAdmin;
        },
        enterEditor() {
            this.$router.push("/edit");
        }
    }
}
</script>

<style lang="scss" scoped>
.archives-wrapper {
    height: 100%;
}

.archives-content {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0 auto;
    align-content: center;
}

.archives-add {
    padding: 25px;
    margin: 0 auto;
}

.archives-grid {
    display: flex;
    flex-wrap: wrap;
}


</style>