import Vue from 'vue';
import VueRouter from 'vue-router';
// import MainPage from "../views/MainPage.vue";
import HomePage from "../views/HomePage.vue";
// import Editor from "../views/Editor.vue";
import TestEditor from "../views/TestEditor.vue";
import Login from "../views/Login.vue";
import Archives from "../views/Archives.vue";
import Issue from "../components/DemoIssue.vue";
import Feeds from "../views/Feeds.vue";
import Contacts from '../views/Contacts.vue';
import Submissions from '../views/Submissions.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { title: "Home" }
  },
  {
    path: "/archives",
    name: "Archives",
    component: Archives,
    meta: { title: "Archives" }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {title: "Contacts"}
  },
  {
    path: "/submissions",
    name: "Submissions",
    component: Submissions,
    meta: {title: "Submissions"}
  },
  {
    path: "/issues/:issueid",
    name: "ViewIssue",
    component: Issue,
    meta: { title: "" }
  },
  {
    path: "/edit",
    name: "Editor",
    component: TestEditor,
    meta: { 
      title: "Edit",
      requiresAuth: false
    }
  },
  {
    path: "/edit/:targetid",
    name: "EditorId",
    component: TestEditor,
    meta: { 
      title: "Edit",
      requiresAuth: false
    }
  },
  {
    path: "/login", 
    name: "Login", 
    component: Login,
    meta: { title: "Login" }
  },
  {
    path: "/feeds",
    name: "Feeds",
    component: Feeds,
    meta: {title: "Feeds"}
  },
  {
    path: "*",
    name: "Not found",
    component: { created: function() { window.location.href = "/"; } }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  document.title = `Furry Weekly | ${to.meta.title}`;
  next();
});

export default router
