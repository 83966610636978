<template>
    <div class="homepage-wrapper">
        <div class="homepage-content">
            <div class="highlights">
                <Highlights/>
            </div>
            <div class="staff-cards">
                <StaffCards/>
            </div>
        </div>
    </div>
</template>


<script>
import Highlights from "../components/Highlights";
import StaffCards from "../components/StaffCards";
export default {
    name: "HomePage",
    components: { Highlights, StaffCards },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@300;400;500;600;700&display=swap");
.homepage-content {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0 auto;
    align-content: center;
    font-family: "Bebas Neue", sans-serif;
	font-style: italic;
}


.big-font {
    font-size: 100px;
}

.highlights {
    margin-top: 20px;
}

.staff-cards {
    margin-top: 20px;
}
</style>