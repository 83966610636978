<template>
	<div class="app-wrapper">
		<div class="app">
			<v-app>
				<v-main>
					<Navigation v-if="navigation"/>
					<router-view></router-view>
					<Footer v-if="navigation"/>
				</v-main>
			</v-app>
		</div>
	</div>
</template>

<script>
import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';

export default {
	name: "app",
	components: {
		Navigation, 
		Footer 
	},
	data() {
		return {
			navigation: true
		};
	},
	methods: {
		checkRoute() {
			if(this.$route.name === "Login") {
				this.navigation = false;
				return;
			}
			this.navigation = true;
		}
	},
	watch: {
		$route() {
			this.checkRoute();
		}
	}


};
</script>

<style lang="scss">
.app-wrapper {
	box-sizing: border-box;
}

.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}


</style>
