<template>
    <div class="issue-card-wrapper">
        <div class="issue-card-content">
            <v-card class="shrink-effect">
                <v-img height="250" :src="cover"></v-img>
                <v-card-title>
                    <div class="issue-card-titlebar">
                        Issue {{ issueID }}
                        <router-link v-if="checkAdmin()" :to="{ name: 'EditorId', params: { targetid: this.issueID } }" style="text-decoration: none; color: inherit;">
                            <v-btn fab small plain><v-icon>mdi-pencil</v-icon></v-btn>
                        </router-link>
                    </div>
                </v-card-title>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "IssueCard",
    props: {
        cover: String,
        issueID: Number
    },

    methods: {
        checkAdmin() {
            return this.$store.getters.getAdmin;
        }
    },
}
</script>

<style lang="scss" scoped>
.issue-card-content {
    width: 100%;
}

.issue-card-titlebar {
        width: 100%;
        display: flex;
        justify-content: space-between;
}

.shrink-effect {
    transition: 0.5s ease;
    &:hover {
        -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
        transition: 0.5s ease;
        cursor: pointer;
    }
}

</style>