import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex); 

const store = new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        admin: false,
    },
    getters: {
        getAdmin: state => state.admin,
    },
    mutations: {
        setAdmin(state, payload) {
            state.admin = payload;
        }
    },
}); 

export default store; 