<template>
    <div class="highlights-wrapper">
        <div class="highlights-content">
            <div v-if="issues != null">
                <h1 style="font-size:6vw">LATEST: Issue {{ issues[0].issueID }}</h1>
                <v-divider></v-divider>
            </div>
            <div v-else>
                <v-skeleton-loader type="article"></v-skeleton-loader>
            </div>
            <div class="covers">
                <div v-if="issues != null" class="latest-cover">
                    <div class="shrink-effect">
                        <router-link :to="{ name: 'ViewIssue', params: { issueid: issues[0].issueID } }">
                            <v-img :src="issues[0].cover">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </router-link>
                    </div>
                </div>
                <div v-if="issues != null" class="recent-covers">
                    <v-container>
                        <v-row>
                            <v-col v-for="idx in 4" :key="idx" cols="12" sm="6">
                                <p style="margin: 0">Issue: {{ issues[idx].issueID }}</p>
                                <router-link :to="{ name: 'ViewIssue', params: { issueid: issues[idx].issueID } }">
                                    <v-img class="shrink-effect" :src="issues[idx].cover" contain>
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </router-link>
                            </v-col>
                        </v-row>               
                    </v-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostService from '../PostService';
export default {
    name: "highlights",
    data() {
        return {
            issues: null,
            last_four: [
                "cover257.png",
                "cover256.png",
                "cover255.png",
                "cover254.png",
            ],
        }
    },

    async beforeCreate() {
        try {
            var res = await PostService.getIssues();
            this.issues = res.sort((a, b) => (a.issueID < b.issueID) ? 1 : -1)
        } catch(err) {
            console.log(err.message);
        }
    },

    methods: {
        coverAtIndex(idx) {
            return require("../assets/" + this.last_four[idx]);
        }
    }
}
</script>

<style lang="scss" scoped>

.highlights-content {
    width: 100%;
    height: 100%;
}

.covers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.latest-cover {
    flex: 50%;
    max-width: 50%;

}

.recent-covers {
    flex: 50%;
    max-width: 50%;
}

@media screen and (max-width: 600px) {
    .covers {
        flex-direction: column;
    }
    .latest-cover {
        max-width: 100%;
    }
    .recent-covers {
        max-width: 100%;
    }
}

.shrink-effect {
    transition: 0.5s ease;
    &:hover {
        -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
        transition: 0.5s ease;
    }
}

</style>