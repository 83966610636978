<template>
    <header>
        <nav class="header-content">
            <img src="../assets/fw_logo.png" style="width: 100px;">
            <div class="header-links">
                <router-link class="link" :to="{ name: 'HomePage' }">HOME</router-link>
                <router-link class="link" :to="{ name: 'Archives' }">ARCHIVES</router-link>
                <router-link class="link" :to="{ name: 'Contacts' }">CONTACT</router-link>
                <router-link class="link" :to="{ name: 'Submissions' }">SUBMIT</router-link>
            </div>
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        </nav>
    </header>
</template>

<script>
export default {
    name: "navigation"
}
</script>

<style lang="scss" scoped>
header{
    background: rgb(214,93,235);
    background: linear-gradient(90deg, rgba(214,93,235,1) 0%, rgba(11,32,153,1) 100%);
    height: 100px;
    padding: 0 20px;
    z-index: 99;

    .header-content {
        display: flex;
        align-items: center;
    }

    .header-links {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content:center;
    }

    .link {
        font-weight: 500;
        padding: 0 8px;
        transition: 0.3s color ease;
        color: white;
        text-decoration: none;

        &:hover { color: #1eb8b8}
    }
}
</style>