<template>
    <div class="login-main-wrap">
        <div class="login-form-wrap">
            <v-card class="text-center">
                <h1>Login</h1>
                <div class="login-error-message" v-if="err != ''">{{err}}</div>
                <v-form class="login-el-wrap" v-model="validForm">
                    <v-text-field label="Email" :rules="emailRules" required v-model="user" v-on:keyup.enter="checkUser"></v-text-field>
                    <v-text-field label="Password" :rules="passRules" :type="showPass ? 'text' : 'password'" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" required v-model="pass" @click:append="showPass = !showPass" v-on:keyup.enter="checkUser">

                    </v-text-field>
                </v-form>
            </v-card>
        </div>
        <div class="text-center">
            <v-btn :disabled="!validForm" @click="checkUser">LOGIN</v-btn>
        </div>
    </div>
</template>

<script>
import PostService from '../PostService';
export default {
    name: "Login", 
    data() {
        return {
            user: "", 
            pass: "", 
            err: "", 
            validForm: false, 
            emailRules: [v => !!v || "Email is required"],
            passRules: [v => !!v || "Password is required"],
            showPass: false, 
        }
    }, 

    methods: {
        async checkUser() {

            let users = await PostService.getUsers(); 
            let result = await PostService.checkLogin(this.pass, this.user, users)
            
            if(result == true) {
                this.err = "";
                this.$store.commit("setAdmin", true);
                this.$router.push("/edit");
            }
            else {
                this.err = "Invalid username or password"; 
                this.$store.commit("setAdmin", false);
            }
        }

    }
}
</script>

<style lang="scss" scoped>
    .login-main-wrap {
        height: 100%;
    }

    .login-form-wrap {
        margin-left: 0px; 
        margin-right: 0px; 
        margin-top: 20vh; 
    }

    .login-error-message {
        color: red;
    }

    .login-el-wrap {
        margin-left: 20px; 
        margin-right: 20px; 
        margin-bottom: 20px; 
    }

    @media only screen and (min-width: 625px) {
        .login-form-wrap {
            margin-left: 20vw; 
            margin-right: 20vw; 
        }
    }

    @media only screen and (min-width: 1100px) {
        .login-form-wrap {
            margin-left: 30vw; 
            margin-right: 30vw; 
        }
    }

    
</style>