import axios from "axios";
// const mongodb = require("mongodb"); 
const bcryptjs = require("bcryptjs");
//const createDomPurify = require("dompurify"); 
//const { JSDOM } = require("jsdom"); 
//const dompurify = createDomPurify(new JSDOM().window);
import dompurify from "dompurify";
import { marked } from "marked";

// const urlSegs = "https://operation-redwood.herokuapp.com/api/segments/"; 
// const urlUser = "https://operation-redwood.herokuapp.com/api/docs/"; 
// const urlNotifs = "https://operation-redwood.herokuapp.com/api/notifications/"; 

const urlSegs = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/segments";
const urlUser = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/users";
const urlNotifs = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/notifications";
const urlIssues = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/issues";
const urlRemoveSeg = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/segments/deleteOne";
const urlRemoveNotif = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/notifications/deleteOne";
const urlEditIssue = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/edit"
// const urlPostIssue = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/update/issue";
// const urlGetIssues = "https://data.mongodb-api.com/app/data-lkqwy/endpoint/issues";

// for dev:
// const urlSegs = "http://localhost:5000/api/segments/";
// const urlUser = "http://localhost:5000/api/docs/";
// const urlNotifs = "http://localhost:5000/api/notifications/"

//const key = "ftZhG3iRnDzWokD59pqNyQd3ZE1GyyBeTUlY2djRWxo2Unw6oRqR4RdFZlPj0G2U"


class PostService {

    
    // GET
    static getIssues() {
        return new Promise((resolve, reject) => {
            try {
                axios.get(urlIssues).then((res) => {
                    const data = res.data;
                    resolve(
                        data.map(post => ({
                            ...post
                        }))
                    )
                })
            } catch(err) {
                reject(err);
            }
        }) 
    }

    static getSegments() {
        return new Promise((resolve, reject) => {
            try {
                axios.get(urlSegs).then((res) => {
                    const data = res.data;
                    resolve(
                        data.map(post => ({
                            ...post
                        }))
                    );
                });
            } catch(err) {
                reject(err);
            }
        });
    }


    static getUsers() {
        return new Promise((resolve, reject) => {
            try {
                axios.get(urlUser).then((res) => {
                    const data = res.data; 
                    resolve(
                        data.map(post => ({
                            ...post, 
                        }))
                    ); 
                }); 
            } catch(err) {
                reject(err); 
            }
        }); 
    }

    
    static getNotifs() {
        return new Promise((resolve, reject) => {
            try {
                axios.get(urlNotifs).then((res) => {
                    const data = res.data; 
                    resolve(
                        data.map(post => ({
                            ...post,
                        }))
                    ); 
                }); 
            } catch(err) {
                reject(err); 
            }
        }); 
    }

    // Login signature
    static checkLogin(pass, user, users) {
        if(bcryptjs.compareSync(pass, users[0].pass) == true && user == users[0].user) {
            return true; 
        }
        return false; 
    } 


    // POST
    static insertPost(text, segment) {
        const safeHTML = dompurify.sanitize(marked.parse(text), { ADD_TAGS: ["iframe"]});
        return axios.post(urlSegs, {
            text, 
            segment,
            safeHTML
        }); 
    }

    static insertNotif(text) {
        return axios.post(urlNotifs, {
            text, 
        });
    }

    static submitIssue(issueID, cover, coverArtist, safeHTML) {
        return axios.post(urlIssues, {
            issueID: issueID,
            cover: cover,
            artist: coverArtist,
            safeHTML: safeHTML,
            hide: true
        });
    }

    
    // PUT
    static swap(post1, post2) {
        let tempPost = post1; 
        axios.put(urlSegs, {
            id: post1._id,
            segment: post2.segment, 
            text: post2.text, 
            safeHTML: post2.safeHTML, 
        })

        return axios.put(urlSegs, {
            id: post2._id,
            segment: tempPost.segment, 
            text: tempPost.text, 
            safeHTML: tempPost.safeHTML, 
        }); 

    }

    static postEdits(id, text, segment) {
        const safeHTML = dompurify.sanitize(marked.parse(text), { ADD_TAGS: ["iframe"]});
        return axios.put(urlSegs, {
            id: id,
            segment: segment, 
            text: text,
            safeHTML: safeHTML
        }); 
    }

    static updateIssue(issueID, cover, artist, safeHTML) {
        return axios.put(urlEditIssue, {
            issueID: issueID,
            cover: cover,
            artist: artist,
            safeHTML: safeHTML,
            hide: true
        });
    }

    //DELETE (as post)
    static deletePost(id) {
        return axios.post(urlRemoveSeg, {id}); 
    }

    static deleteNotif(id) {
        return axios.post(urlRemoveNotif, {id}); 
    }
}

export default PostService; 