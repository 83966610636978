<template>
    <div class="edit-wrapper">
        <div class="edit-content">
            <div class="submission">
                <v-card>
                    <v-card-title>Issue Submission</v-card-title>
                    <div class="submission-form">
                        <v-form>
                            <v-text-field label="Issue #" :rules="mustBeNum" v-model="issueID"></v-text-field>
                            <v-text-field label="Cover Link" v-model="coverURL"></v-text-field>
                            <v-text-field label="Cover Artist" v-model="coverArtist"></v-text-field>
                            <v-file-input v-model="file" @change="toHTML()" counter show-size clearable accept=".md"></v-file-input>
                            <v-btn @click="uploadEdits()">UPLOAD</v-btn>
                        </v-form>
                    </div>
                </v-card>
            </div>
            <div class="preview">
                <v-card v-if="coverURL != null" >
                    <v-card-title>PREVIEW COVER</v-card-title>
                    <v-img class="preview-cover" :src="coverURL"></v-img>
                </v-card>
                <div class="issue-container">
                    <v-card>
                        <v-card-title>PREVIEW BODY</v-card-title>
                        <div v-if="safeHTML != null" class="preview-content" v-html=safeHTML></div>
                    </v-card>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostService from '../PostService';
import dompurify from "dompurify";
import { marked } from "marked";
export default {
    name: "Editor",
    data() {
        return {
            mustBeNum: [ 
                v => {
                    if (!isNaN(parseFloat(v))) return true;
                    return "Must be a number"
                }
            ],
            render: false,
            file: null,
            issueID: null,
            coverURL: "https://drive.google.com/uc?id=",
            coverArtist: null,
            safeHTML: null,
        }
    },

    async beforeCreate() {
        if(this.$store.getters.getAdmin == false) {
            this.$router.push("/login"); 
        }
        
        if(this.$route.params.targetid != null) {
            try {
                var issues = await PostService.getIssues();
                var issue = issues.filter(e => e.issueID === parseFloat(this.$route.params.targetid))[0];
                this.issueID = issue.issueID;
                this.coverURL = issue.cover;
                this.coverArtist = issue.artist;
                this.safeHTML = issue.safeHTML;
            } catch(err) {
                console.log(err.message);
            }
        }

    },

    methods: {

        async uploadEdits() {
            if (this.safeHTML == null || this.issueID == null) return;
            var issues = await PostService.getIssues();
            if (issues.filter(e => e.issueID === parseFloat(this.issueID)).length > 0) await PostService.updateIssue(parseFloat(this.issueID), this.coverURL, this.coverArtist, this.safeHTML);
            else await PostService.submitIssue(parseFloat(this.issueID), this.coverURL, this.coverArtist, this.safeHTML);
        },

        toHTML() {
            if (this.file == null) {
                this.safeHTML = null;
                return;
            }
            var self = this;
            var fr = new FileReader();
            fr.readAsText(this.file);
            fr.onload = async function() {
                self.safeHTML = dompurify.sanitize(marked.parse(fr.result), { ADD_TAGS: ["iframe"]});
            };
        },
    }

}
</script>

<style lang="scss">

.edit-wrapper {
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 10px;
}

.edit-content {
    width: 100%;
    align-items: center;
}

.submission {
    max-width: 500px;
    margin: 0 auto;
    padding: 10px;
}

.submission-form {
    width: 100%;
    padding: 10px;
}

.preview-cover {
    width: 50%;
    margin: 0 auto;
}

.issue-container {
    margin-top: 10px;
    margin-bottom: 20px;
}

.preview-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

</style>