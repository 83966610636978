<template>
    <div class="submissions-wrapper">
        <div class="submissions-content">
            <div class="submissions-title">Send Us Your Work</div>
            <div>
                At its core, Furry Weekly aims to showcase the creativity the fandom has to offer, and most of our segments center around featuring folks in the community! 
                We have three different ways 
            </div>

            <div class="submissions-title">Email Us</div>
            <div>
                Email us at <a href="mailto:furryweeklysubmissions@gmail.com">furryweeklysubmissions@gmail.com</a>! Here, you can send us your work, like art, writing, or anything else! You can also tell us you’re interested in appearing in a specific segment that doesn’t necessarily require you to submit something, like Character Interview, Furry of the Week, or Fursuit Focus. You can also send us suggestions for who to feature. 
                <br><br>
                In your email, be sure to tell us exactly what segment you want to be featured in, or are suggesting someone to be featured in.
            </div>

            <div class="submissions-title">Fill Out Our Form</div>
            <div>
                We have an online form you can fill out based on the content you want to fill. Just follow the link below and check it out if you’d like to submit something!
            </div>
            <div class="send-work-center">
                <a href="https://forms.gle/ThEqiQNpvrhy8qWi8">Submissions Form</a>
            </div>

            <div class="submissions-title">Social Media</div>
            <div>
                You can also use our hashtags on social media to submit your work. Whenever you post something, just use the tag #furryweekly plus the tag of whatever segment you’d like to be featured in (e.g. #artcorner, #writingshowcase, #fursuitfocus).
                <br><br>
                We can’t always find posts with hashtags, so while we still look, we recommend you either email us or fill out our form. You can also reach out directly to the host of the segment you’d like to be featured in. You can find contact information for our hosts in the
                <a href="https://www.furryweekly.com/contacts">Contact</a>
                section of our website.
                <br><br>
                Remember that if you either email us or fill out our Submissions Form, you’re giving us permission to feature you or your work here on the magazine! We will always link back to you if you or your work appears on Furry Weekly!
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "submissions",
    data() {
        return {}
    }

}
</script>

<style lang="scss" scoped>
.submissions-wrapper {
    height: 100%;
}

.submissions-content {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0 auto;
    align-content: center;
}

.submissions-center {
    display: flex;
    justify-content: center;
}

.submissions-title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 30px;
    margin-top: 20px;
}

.submissions-subtitle {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
}

</style>